export const isMobileDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  const mobileKeywords = [
    'mobile',
    'android',
    'ios',
    'iphone',
    'ipad',
    'ipod',
    'blackberry',
    'windows phone',
    'iemobile',
    'opera mini',
    'fennec',
    'webos',
    'bolt',
    'teashark',
    'zte open',
    'kddi',
    'vodafone',
    'docomo',
    'softbank',
    'googlebot-mobile',
  ];

  return mobileKeywords.some(keyword => userAgent.includes(keyword));
};
